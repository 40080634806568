.Textarea {
  font-family: 'SourceSansPro';

  label {
    cursor: pointer;
    padding-left: 1rem;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
  }
  .inputBlock {
    margin-top: 0.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    border: 1.5px solid #96A0B0;
    min-height: 150px;

    textarea {
      font-family: 'SourceSansPro';
      resize: none;
      min-height: 60px;
      background-color: transparent;
      color: #454b66;
      font-weight: 500;
      font-size: 16px;
      border: 0px;
      width: 100%;
      height: 100%;
      outline: none;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar {
        width: 6px;
        height: 3px;
        border-radius: 3px;
        background-color: #e4e6ea;
      }

      &::-webkit-scrollbar-button,
      &::-webkit-scrollbar-button {
        background-color: #96a0b0;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb {
        height: 30px;
        width: 10px;
        border-radius: 8px;
        background-color: #ccd1d8;
      }

      &::placeholder {
        font-family: 'SourceSansPro';
        color: #96A0B0;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
}
