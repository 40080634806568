.card {
  border-bottom: 1px solid #f0f2f3;
  padding: 12px 0;
  .cardHead {
    display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 10px;
    padding-bottom: 8px;
  }
  .cardHeadTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #2C2F36;
  }
  .cardBodyRow {
    display: grid;
    grid-template-columns: 120px 1fr;
    column-gap: 10px;
    padding: 4px 0;
  }
  .cardBodyTitle {
    font-size: 14px;
    line-height: 16px;
    color: #96A0B0;
  }
  .cardBodyText {
    font-size: 16px;
    line-height: 16px;
    color: #2C2F36;
    display: flex;
    align-items: center;
  }
}
