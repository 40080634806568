.giveBCoinsForm {
  background-color: #ffffff;
  padding: 2rem;
  max-width: 340px;
  border-radius: 12px;
}

.twoCols {
  display: grid;
  grid-template-columns: 340px 1fr;
  column-gap: 35px;
}

.sendBCoinsFormTwoCols {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 50px;
}

.bCoinsHistoryList {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
}

.FiltersBlock {
  display: grid;
  padding-right: 1rem;
  grid-template-columns: 3fr 3fr 2fr;
  column-gap: 10px;
  align-items: end;
}
.achievementsCardsListTitle {
  font-size: 30px;
  font-weight: 700;
  color: #2c2f36;
}
.totalPointsReceive {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  white-space: nowrap;
}

.achievementsList {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  max-height: 564px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    border-radius: 3px;
    background-color: #e4e6ea;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #96a0b0;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #ccd1d8;
  }
}

.projectsContainer {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 45px;
  row-gap: 20px;
}
.projectsContainerOneCol {
  display: flex;
}
.projectsContainerText {
  font-size: 14px;
  color: #96a0b0;
}
.allMyProjectsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  column-gap: 10px;
  padding: 10px;
  margin: 0 -10px;
  max-height: 518px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    border-radius: 3px;
    background-color: #e4e6ea;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #96a0b0;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #ccd1d8;
  }
}

.notIsMainProjectsTableContainer {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
}

.projectCard {
  cursor: pointer;
  min-height: 215px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 20px 30px;
  margin-bottom: 10px;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .cardTitle {
    color: #2c2f36;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    margin-top: 8px;
    word-break: break-word;
  }
  .cardRole {
    color: #2c2f36;
    font-weight: normal;
    font-size: 13px;
    margin-top: 8px;
  }
  .cardImg {
    width: 115px;
    height: 115px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
  }
  .cardIcon {
    max-width: 100%;
    height: auto;
    display: block;
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }
}
.table {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px;
  display: block;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    border-radius: 3px;
    background-color: #e4e6ea;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #96a0b0;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #ccd1d8;
  }
}

.rowItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  margin-bottom: 8px;
  background: #f5f6f6;
  padding: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
  .imgBlock {
    width: 52px;
    height: 52px;
    margin-right: 15px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .infoBlock {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 8px;
    .name {
      color: #2c2f36;
      font-size: 16px;
    }
    .role {
      margin-top: 5px;
      font-size: 14px;
      color: #96a0b0;
    }
  }
}

.userContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .imgContainer {
    width: 86px;
    height: 86px;
    border-radius: 8px;
    margin-right: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
    svg {
      width: 86px;
      height: 86px;
    }
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      color: #2c2f36;
      font-weight: bold;
      font-size: 28px;
    }
    .counters {
      display: flex;
      flex-direction: row;
      align-items: center;
      .counterBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 25px;
        span {
          padding: 0.5rem 0;
          text-align: center;
          min-width: 65px;
          background: #fff;
          border-radius: 6px;
          font-weight: bold;
          font-size: 28px;
          color: #5f37ff;
        }
        .counterText {
          font-weight: 600;
          margin-left: 10px;
          font-size: 16px;
          color: #2c2f36;
        }
      }
    }
  }
}

.twoCols2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
.EmptyTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  .Text {
    font-size: 14px;
    font-family: 'SourceSansPro';
    font-weight: 400;
    color: #ccd1d8;
  }
}
.slidersContainer {
  text-align: center;
}
.infoContainerControls {
  width: 100%;
  display: none;
}
@media screen and (max-width: 1200px) {
  .twoCols2 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 992px) {
  .FiltersBlock {
    padding-right: 0px;
    display: block;
  }

  .achievementsCardsListTitle {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .twoCols {
    display: flex;
    flex-direction: column;
  }
  .bCoinsHistoryList {
    padding: 1rem;
    margin: 1rem;
  }
  .giveBCoinsForm {
    max-width: 100%;
    padding: 1rem;
    margin-bottom: 20px;
  }
  .rc-slider-mark {
    left: 4%;
  }
  .projectsContainer {
    display: flex;
    flex-direction: column;
  }
  .userContainer {
    flex-direction: column;
    align-items: center;
    .imgContainer {
      margin-bottom: 12px;
    }
    .infoContainer {
      align-items: center;
      .name {
        margin-bottom: 16px;
        text-align: center;
      }
      .counters {
        flex-wrap: wrap;
        align-items: flex-start;
        .counterBlock {
          margin-bottom: 10px;
        }
      }
    }
  }
  .infoContainerControls {
    display: flex;
    flex-direction: column;
  }
  .achievementsList,
  .allMyProjectsContainer,
  .table {
    max-height: unset !important;
  }

}

@media screen and (max-width: 480px) {
  .userContainer {
    .infoContainer {
      align-items: flex-start;
      .counters {
        flex-direction: column !important;
      }
    }
  }
  .allMyProjectsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    column-gap: 8px;
    row-gap: 8px;
    padding: 8px;
    margin: 0;
    max-height: 518px;
    overflow: hidden;
    overflow-y: auto;
  }
  .projectCard {
    min-height: 144px;
    padding: 16px;
    .cardImg {
      width: 72px;
      height: 72px;
      margin: 0px 0;
    }
  }
  .achievementsList {
    padding: 20px 10px;
  }
  .table {
    padding: 1rem;
  }
  .totalPointsReceive{
    justify-content: flex-start;
  }
  .bCoinsHistoryList {
    padding: 1rem;
    margin: 0rem;
  }
}
