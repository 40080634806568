.Events {
  padding: 1rem;
}
.CardsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}
.filtersRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  div {
    margin-right: 20px;
  }
}
@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {
  .filtersRow {
    flex-wrap: wrap;
  }
  .filterContainer {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 480px) {
  .filtersRow {
    flex-direction: column;
  }
  .filterContainer {
    width: 100%;
  }
}
