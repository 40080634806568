.PurchaseListTab {
  .PurchaseListTaButton {
    margin-left: 10px;
    display: flex;
    font-family: 'SourceSansPro' !important;
    align-items: center;
    justify-content: space-between;
    background: #5f37ff;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 0.75rem 1rem;
    border: 2px solid #5f37ff;
    outline: none;
    cursor: pointer;
    svg {
      display: block;
      margin-left: 5px;
      path {
        stroke: #ffffff;
      }
    }
    &:hover {
      border: 2px solid #5f37ff;
      color: #5f37ff;
      background: #ffffff;
      svg {
        path {
          stroke: #5f37ff;
        }
      }
    }
  }
}
.pageContent {
  background: white;
  border-radius: 12px;
}
.filterBlock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.filterCell {
  width: 100%;
  display: flex;
  justify-content: space-between;
}