.TextInput {
  position: relative;
  font-family: 'SourceSansPro';
  label {
    cursor: pointer;
    padding-left: 1rem;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
  }
  .inputBlock {
    margin-top: 0.3rem;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.5px solid #96A0B0;
    input {
      padding-left: 0.85rem;
      padding-bottom: 0.75rem;
      padding-top: 0.75rem;
      width: 100%;
      font-family: 'SourceSansPro';
      background-color: transparent;
      color: #454b66;
      font-weight: 500;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #96A0B0;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
}
