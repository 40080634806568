.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}
.flex {
  display: flex;
}
.editUserFormButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    min-width: 109px;
  }
}
.userCard {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    img {
      border-radius: 8px;
      width: 72px;
      height: 72px;
    }
    .name {
      font-weight: 500;
      font-size: 16px;
      color: #2c2f36;
      padding-bottom: 0.5rem;
    }
    .role {
      font-weight: 500;
      font-size: 12px;
      color: #d5d9de;
    }
  }
  .block {
    cursor: pointer;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    color: #96a0b0;

    svg {
      margin-top: -5px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      path {
        color: #96a0b0;
      }
    }
  }
  .userDataBlock {
    label {
      display: block;
      padding: 20px 0 0 16px;
      font-size: 11px;
      line-height: 110%;
      color: #96a0b0;
    }

    .checkboxRow {
      width: 100%;
      display: flex;
      margin: 16px 0 10px 0;

      > div:not(:last-child) {
        margin-right: 33px;
      }
    }

  }
  .userRoleChanger {
    width: 208px;
    margin-left: 16px;
    label {
      display: block;
      padding: 7px 0 0 16px;
      font-size: 11px;
      line-height: 110%;
      color: #96a0b0;
      margin-bottom: 5px;
    }
  }

  .unblock {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    color: #00df31;

    svg {
      margin-top: -5px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      path {
        fill: #00df31;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .userCard {
    .userRoleChanger {
      width: 170px;
      margin-left: 1rem;
    }
    .btn {
      margin-top: 15px;
    }
  }
  .twoColumns {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .userCard {
    .editUserFormButton {
      flex-direction: column;
      button {
        order: 1;
        min-width: 100%;
        margin-bottom: 20px;
      }
    }
    .unblock,
    .block {
      order: 2;
    }
  }
}
