.Header {
  background-color: #ffffff;
  min-height: 40px;
  padding: 1rem;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 100px 1.2fr 1fr;
  align-items: center;
  max-width: 1110px;
  width: calc(100% - 2rem);
  box-shadow: 0px 18px 18px rgba(0, 12, 58, 0.02);
  border-radius: 12px;

  .logo {
    cursor: pointer;
    font-style: italic;
    font-weight: 900;
    font-size: 17px;
    line-height: 100%;
    text-transform: uppercase;
    color: #2c2f36;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.searchUsers {
  width: 230px;
  margin-right: 12px;
}
.UserInfoBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .Header {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .Header {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }
  .UserInfoBlock {
    display: none;
  }
}
