.Pagination {
  color: #c2c2c2;
  font-size: 16px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #2C2F36;
    box-shadow: 0px 3px 4px rgba(0, 12, 58, 0.1);
    border-radius: 6px;
    width: 38px;
    height: 38px;
    outline: none;
    background: white;
    cursor: pointer;
    margin: 0 5px;
    border: 1px solid transparent;
    transition: 0.3s;
    &.active,
    &:hover {
      color: #ffffff;
      background: #5F37FF;
    }
  }
  .pagButtonDot {
    margin: 0 5px;
    color: #263238;
  }
  .pagButtonArrow {
    border: 1px solid transparent;
    box-shadow: 0px 3px 4px rgba(0, 12, 58, 0.1);
    border-radius: 6px;
    width: 38px;
    height: 38px;
    transition: 0.3s;
    svg {
      fill: #263238;
      transition: 0.3s;
    }
    &.active,
    &:hover {
      color: #ffffff;
      background: #5F37FF;
      svg {
        fill: #ffffff;
      }
    }
  }
  .pagButtonArrow__prev {
    svg {
      transform: rotate(90deg);
      margin-bottom: 2px;
      fill: #5F37FF;
    }
  }
  .pagButtonArrow__next {
    svg {
      transform: rotate(-90deg);
      margin-bottom: 2px;
      fill:  #5F37FF;
    }
  }
}
