.projectCard {
  .projectFormRow {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    column-gap: 40px;
    .imageInputWrapper {
      width: 128px;
      height: 128px;
    }
    .projectFormRowImageInput {
      background: none;
    }
  }
  .projectFormCol {
    display: flex;
    flex-direction: column;
  }
  .projectsUsersFilter {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 20px;
    .projectsUsersCount {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 110%;
      color: #96a0b0;
      padding-top: 20px;
    }
  }
  .betweenRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .removeBtn {
    cursor: pointer;
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #96a0b0;
    background-color: transparent;
    border: 0px;
    outline: none;
    width: auto;
    padding: 0px;
  }
  .EditProjectFormButton {
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    button {
      min-width: 120px;
    }
  }
}
.projectUserForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  .selectors {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .projectUserFormIsMain {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  .removeProjectBtn {
    cursor: pointer;
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #96a0b0;
    background-color: transparent;
    border: 0px;
    outline: none;
    width: auto;
    padding: 0px;
  }
  .setProjectBtn {
    cursor: pointer;
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #5f37ff;
    text-transform: uppercase;
    background-color: transparent;
    border: 0px;
    outline: none;
    width: auto;
    padding: 0px;
  }
}
.projectUserFormRole {
  min-width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 991px) {
  .projectCard {
    padding: 0px;
    .projectFormRow {
      display: block;
      .imageInputWrapper {
        width: 100%;
        height: auto;
      }
    }
    .projectFormCol {
      padding: 1rem;
    }
    .removeBtn {
      font-size: 13px;
      svg {
        width: 15px;
        height: 16px;
      }
    }
    .projectUsersBlock {
      padding: 1rem;
    }
    .betweenRow {
      padding: 1rem;
      button {
        font-size: 13px;
      }
    }
  }
}
.TextInput {
  position: relative;
  font-family: 'SourceSansPro';
  label {
    cursor: pointer;
    padding-left: 1rem;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
  }
  .inputBlock {
    margin-top: 0.3rem;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #ccd1d8;
    input {
      padding-left: 1rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
      width: 100%;
      font-family: 'SourceSansPro';
      background-color: transparent;
      color: #454b66;
      font-weight: 500;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #ccd1d8;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .EditProjectFormButton {
    flex-direction: column-reverse;
    button {
      min-width: 100%;
      margin-bottom: 20px;
    }
  }
}