.Search {
  position: relative;
  font-family: 'SourceSansPro';

  &:hover ul {
    display: block;
  }
  label {
    cursor: pointer;
    padding-left: 1rem;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 8px;
    display: block;
  }
  .inputBlock {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.5px solid #96A0B0;
    input {
      padding-left: 0.85rem;
      padding-bottom: 0.75rem;
      padding-top: 0.75rem;
      width: 80%;
      font-family: 'SourceSansPro';
      background-color: transparent;
      color: #454b66;
      font-weight: 500;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #96A0B0;
        font-weight: 500;
        font-size: 12px;
      }
    }
    svg {
      padding: 0.75rem;
      float: right;
    }
    .clearInput {
      width: 20px;
      height: 20px;
      &:hover path {
        fill: #454b66;
      }
    }
  }
  ul {
    z-index: 9999;
    font-family: 'SourceSansPro';
    max-height: 350px;
    overflow: auto;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #454b66;
      padding: 1rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #fbfbfb;
      }
      .searchUserImg {
        border-radius: 8px;
        height: 30px;
        width: 30px;
        margin-right: 1rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
        svg {
          height: 30px;
          width: 30px;
          background-color: #ebeef1;
          padding: 5px;
        }
      }
    }
  }
}
