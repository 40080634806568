a {
  color: inherit;
  text-decoration: none;
  display: inline;
}
.card {
  min-height: 300px;
  display: flex;
  padding: 2rem 1rem;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .avatar {
    margin: 0 auto;
    margin-bottom: 12px;
    text-align: center;
    border-radius: 12px;
    width: 88px;
    height: 88px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      border-radius: 12px;
      padding: 1rem;
      background-color: #ebeef1;
    }
  }
  .name {
    color: #2c2f36;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .blaceTheme {
    text-align: center;
    color: #96a0b0;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 2px;
  }
  .category.bcoinsBlock {
    margin-right: 8px;
    max-width: 65px;
    flex: 1
  }
  .bcoins {
    color: #5F37FF;
    font-size: 14px;
    font-weight: 500;
    margin-top: -3px;
    text-align: center;
    line-height: 16px;
    white-space: nowrap;
    svg {
      margin-bottom: -4px;
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
  }
  .categoryName {
    flex: 3
  }
  .category {
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 12px 0px;
    border-radius: 6px;
    font-weight: 600;
    color: #5f37ff;
    background-color: #e5e3fe;
    white-space: nowrap;
    max-width: 175px;
  }
  .comment {
    text-align: center;
    color: #96a0b0;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .givenToName {
    text-align: center;
    color: #2c2f36;
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
  }
  .createdDate {
    color: #cacfd7;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 120%;
    margin-top: 8px;
  }
}
