.Card {
  max-width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .imageWrapper {
    border-radius: 6px;
    width: 105px;
    height: 105px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }
  svg {
    width: 125px;
    height: 125px;
  }

  .title {
    text-align: center;
    font-weight: 600;
    line-height: 120%;
    font-size: 17px;
    color: #2c2f36;
  }

  &:hover .modal {
    display: flex;
  }
}
.modal {
  display: flex;
  padding: 1rem;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-box-shadow: 2px 10px 19px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 19px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 10px 19px -10px rgba(0, 0, 0, 0.75);
  max-width: 500px;
  .modalImage {
    border-radius: 6px;
    width: 125px;
    overflow: hidden;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
    svg {
      height: 125px;
      width: 125px;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 2rem;
    padding-right: 3rem;
    .modalSubTitleMobile,
    .modalSubTitle {
      color: #5f37ff;
      font-size: 14px;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
    .modalSubTitleMobile {
      display: none;
    }
    .modalTitle {
      padding-bottom: 1rem;
      color: #2c2f36;
      font-size: 18px;
    }
    .modalDescription {
      color: #2c2f36;
      font-size: 14px;
    }
  }

  .modalDate {
    color: #ccd1d8;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 1rem;
  }
  .modalClose {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 1rem;
    color: #eaecef;
  }
}
@media screen and (max-width: 991px) {
  .Card {
    max-width: 115px;
    .imageWrapper {
      width: 115px;
      height: 115px;
    }
    svg {
      width: 115px;
      height: 115px;
    }
  }
  .modal {
    max-width: 290px;
    padding: 0px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .modalClose {
      display: block;
    }
    .modalImage {
      width: 100%;
      height: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-bottom: 24px;

      svg {
        width: 100%;
        height: auto;
      }
    }
    .modalContent {
      text-align: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      .modalTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
      }
      .modalSubTitleMobile {
        font-size: 12px;
        display: block;
      }
      .modalSubTitle {
        display: none;
      }
      .modalDescription {
        color: #56595e;
        padding-bottom: 40px;
      }
    }
  }
}
