.card {
  min-height: 236px;
  display: flex;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  .cardBlock {
    padding: 8px 12px 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .cardAvatar {
    height: 140px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: antiquewhite;
  }
  .type {
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    color: #2C2F36;
    margin-bottom: 12px;
  }
  .offerBlock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    .offerTitle,
    .offerPrice {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #2C2F36;
    }
    .offerTitle {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 10px;
    }
    .offerPrice {
      white-space: nowrap;
    }
  }
  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2C2F36;
    flex: 1;
  }
}