html {
  min-height: calc(100vh - 30px);
  height: 100%;
  width: 100%;
  background: #f8f8f8;
  color: #18224c;
  font-family: 'SourceSansPro' ;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  min-height:  100%;
  height:  0%;
  margin: 0;
  position: relative;
}
a {
  width: unset;
}
#root {
  min-height:  100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.main {
  max-width: 1070px;
  margin: 0 auto;
  width: 100%;
  flex: 1;
}
.mr1 {
  margin-right: 1rem;
}
.ml1 {
  margin-left: 1rem;
}
.mt1 {
  margin-top: 1rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb15 {
  margin-bottom: 1.5rem;
}
.m1 {
  margin: 1rem;
}

.mr2 {
  margin-right: 2rem;
}
.ml2 {
  margin-left: 2rem;
}
.mt2 {
  margin-top: 2rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mb25 {
  margin-bottom: 2.5rem;
}
.m2 {
  margin: 2rem;
}

.mr3 {
  margin-right: 3rem;
}
.ml3 {
  margin-left: 3rem;
}
.mt3 {
  margin-top: 3rem;
}
.mb3 {
  margin-bottom: 3rem;
}
.m3 {
  margin: 3rem;
}

.mr4 {
  margin-right: 4rem;
}
.ml4 {
  margin-left: 4rem;
}
.mt4 {
  margin-top: 4rem;
}
.mb4 {
  margin-bottom: 4rem;
}
.m4 {
  margin: 4rem;
}

.pr3 {
  padding-right: 16px;
}

.titleH1 {
  font-size: 30px;
  font-weight: 700;
  color: #2c2f36;
}

.error-message-notice {
  -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  width: 100%;
  z-index: 100;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message-notice-content {
  -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  padding: 20px;
  opacity: 1;
  top: 40px;
  position: fixed;
  padding: 15px;
  min-width: 200px;
  background-color: #ffffff;
  border-radius: 4px;
  font-weight: 400;
  color: #3a3b43;
  display: flex;
  justify-content: space-around;
  align-items: center;
  animation-name: MessageMoveOut;
  animation-duration: 0.5s;
}

// .error-message-notice-content svg {
//   margin-right: 10px;
//   background-color: red;
//   fill: #ffffff;
//   stroke: #ffffff;
//   stroke-width: 2;
//   border-radius: 50%;
//   padding: 3px;
// }

@keyframes MessageMoveOut {
  0% {
    top: -100px;
    opacity: 0;
  }
  100% {
    top: 40px;
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .main {
    padding: 0 1rem;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 991px) {
  .ml2 {
    margin-left: 0rem;
  }
  .mt4 {
    margin-top: 2rem;
  }
}

.LoadScreen {
  position: absolute;
  top: 45%;
  right: 43%;
}
@media screen and (max-width: 480px) {
  .main {
    margin-left: 0rem ;
    margin-right: 0rem ;
  }
}
