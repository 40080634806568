.StoreDetail {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;

  .storeInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    min-height: 256px;
    margin-bottom: 60px;

    .cardPicture {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 24px;
      overflow: hidden;
      height: 338px;
      max-width: 520px;
      width: 100%;
    }
    .offerCountProgress{
      background: #CCD1D8;
      border-radius: 8px;
      width: 100%;
      height: 16px;
      position: relative;
      margin-top: 16px;
      overflow: hidden;
    }
    .offerCountProgressBar {
      background: #5F37FF;
      border-radius: 8px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: right;
    }
    .offerCountProgressBarText {
      padding: 0 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
    }
    .offerCountProgressDesc {
      display: flex;
      flex-direction: column;
    }
    .offerCountProgressCoin,
    .storeDescriptionPrice {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #2C2F36;
      margin-bottom: 5px;
    }
    .offerCountProgressCoinSubtitle{
      font-size: 16px;
      line-height: 19px;
      color: #2C2F36;
    }
    .NumInputContainer {
      margin-top: 32px;
      display: flex;
      align-items: flex-end;
    }
    .storeText {
      display: flex;
      flex-direction: column;

      .storeTitle {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: #2C2F36;
        margin-bottom: 12px;
      }
      .storeDescription {
        font-size: 16px;
        line-height: 19px;
        color: #96A0B0;
        margin-bottom: 16px;
      }
    }
  }
  .storeHistory {
    width: 50%;
    margin: 0 auto;
    .storeTitle {
      font-weight: bold;
      font-size: 20px;
      color: #2C2F36;
      text-align: center;
}
}
}

.breadcrumbsBlock {
display: flex;
flex-direction: row;

.breadcrumbsArrow {
color: #2c2f36;
fill: #2c2f36;
margin-right: 10px;
}
.breadcrumb {
color: #2c2f36;
font-size: 14px;
margin-right: 10px;
}
.breadcrumbActive {
font-size: 14px;
color: #5f37ff;
margin-right: 10px;
}
}

@media screen and (max-width: 768px) {
.StoreDetail {
padding: 0;
.storeInfo {
  grid-template-columns: 1fr;
  .cardPicture {
    max-width: 100%;
    margin-bottom: 16px;
  }
}
.storeHistory {
  width: 100%;

}
}

}
@media screen and (max-width: 480px) {
.StoreDetail {
.storeInfo {
  .storeDescriptionPrice {
    margin-bottom: 0px;
  }
  .storeText {
    .storeTitle,
    .offerCountProgressCoin {
      font-size: 24px;
      line-height: 24px;
    }
    .storeDescription {
      margin-bottom: 24px;
    }
  }
  .cardPicture {
    height: 162px;
    max-width: 288px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
}
}
}
