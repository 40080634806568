.Auth {
  background-color: #5f37ff;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .AuthForm {
    color: #2c2f36;
    background-color: #ffffff;
    margin-right: 85px;
    border-radius: 12px;
    padding: 4rem;
    .titleBlock {
      text-align: center;
      .title {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 60px;
        font-style: italic;
        color: #2c2f36;
      }
      .subTitle {
        padding-top: 1rem;
        color: #96a0b0;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .AuthButtonMobile,
    .AuthButton {
      text-transform: uppercase;
      max-width: 365px;
      background: #008952;
      border-radius: 4px;
      color: #ffffff;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      padding: 1rem;
      border: 1px solid #008952;
      outline: none;
      text-decoration: none;
      cursor: pointer;
    }
    .AuthButtonMobile {
      display: none;
    }
    .AuthFooter {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #2c2f36;
      width: 100%;
      font-weight: 500;
      .copyright {
        color: #88898a;
      }
      .footerLinks {
        margin-bottom: 10px;
        .link {
          text-decoration: none;
          color: #2c2f36;
          &:hover {
            text-decoration: underline;
          }
        }
        .separator {
          font-weight: 800;
          margin-right: 15px;
          margin-left: 15px;
          color: #2c2f36;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .Auth {
    .AuthForm {
      margin-right: 15px;
      margin-left: 15px;
      padding: 2rem;
      .titleBlock {
        .title {
          font-size: 50px;
        }
        .subTitle {
          font-size: 12px;
        }
      }
      .AuthButton {
        display: none;
      }
      .AuthButtonMobile {
        display: block;
      }
      .AuthFooter {
        font-size: 11px;
      }
    }

    svg {
      display: none;
    }
  }
}
