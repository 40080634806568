.Select {
  position: relative;
  font-family: 'SourceSansPro';
  outline: none;

  &:active ul,
  &:focus ul {
    display: block;
  }
  &:active svg,
  &:focus svg {
    transform: rotate(180deg);
  }
  &:active .inputBlock,
  &:focus .inputBlock {
    display: block;
    border: 1.5px solid #616976;
  }

  label {
    cursor: pointer;
    padding-left: 1rem;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    margin-top: 4px;
    path {
      fill: #96a0b0;
    }
  }
  .inputBlock {
    margin-top: 0.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.7rem;
    border: 1.5px solid #96A0B0;
    input {
      cursor: pointer;
      font-family: 'SourceSansPro';
      width: 80%;
      background-color: transparent;
      color: #454b66;
      font-weight: 500;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #96A0B0;
        font-weight: 500;
        font-size: 12px;
      }
    }
    svg {
      float: right;
    }
  }
  .clearBtn {
    width: 15px;
    height: 15px;
    z-index: 2;
    margin-top: 4px;
    path {
      fill: #ccd1d8;
      cursor: pointer;
    }
  }
  ul {
    z-index: 10;
    font-family: 'SourceSansPro';
    display: none;
    max-height: 350px;
    overflow: auto;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #454b66;
      padding: 1rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:active,
      &:focus {
        background-color: #fbfbfb;
      }
      .avatar,
      svg {
        border-radius: 8px;
        background-color: #ebeef1;
        padding: 5px;
        height: 40px;
        width: 40px;
        margin-right: 1rem;
      }
    }
  }
}
