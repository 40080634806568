.SelectFilter {
  position: relative;
  font-family: "SourceSansPro";
  outline: none;
  & + & {
    margin-left: 40px;
  }
  &:active ul,
  &:focus ul {
    display: block;
  }
  &:active .arrow,
  &:focus .arrow {
    transform: rotate(180deg);
  }
  &:active .inputBlock,
  &:focus .inputBlock {
    display: block;
    border: 1px solid #eff2f4;
  }
  .arrow {
    width: 15px;
    height: 15px;
    margin-top: 4px;
    path {
      fill: #96a0b0;
    }
  }
  .inputBlock {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 13px;
    padding-left: 16px;
    height: 20px;
    padding-right: 16px;
    border: 1px solid #eff2f4;
    background-color: #eff2f4;
    z-index: 1;
    width: calc(100% - 30px);

    input {
      cursor: pointer;
      width: 80%;
      height: 100%;
      background-color: transparent;
      font-family: "SourceSansPro";
      font-weight: normal;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #96a0b0;
        font-family: "SourceSansPro";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
    }
    svg {
      z-index: 2;
      float: right;
    }
  }
  .clearBtn {
    width: 13px;
    height: 13px;
    z-index: 2;
    margin-top: 5px;
    path {
      fill: #96a0b0;
      cursor: pointer;
    }
  }
  ul {
    z-index: 10;
    margin-bottom: 20px;
    font-family: "SourceSansPro";
    display: none;
    max-height: 350px;
    overflow: auto;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #454b66;
      padding: 1rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:active,
      &:focus {
        background-color: #fbfbfb;
      }
      .avatar,
      svg {
        border-radius: 8px;
        background-color: #ebeef1;
        padding: 5px;
        height: 40px;
        width: 40px;
        margin-right: 1rem;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .SelectFilter {
    width: 100% !important;
  }
}
