.tabsContainer {
  display: flex;
  flex-direction: column;
}
.buttonsBlock {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 45px;
}
.button {
  height: 48px;
  &:focus {
    outline: none;
  }
  button {
    background: #ffffff;
    color: #5f37ff;
  }
}
.buttonActive {
  button {
    background: #5f37ff;
    color: #ffffff;
  }
}

