.CardsList {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(125px,1fr));
  max-width: 100%;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 30px;
  row-gap: 30px;
  padding-right: 1rem;
}

.LoadScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
}

.EmptyTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  .Text {
    font-size: 14px;
    font-family: 'SourceSansPro';
    font-weight: 400;
    color: #ccd1d8;
  }
}

@media screen and (max-width: 991px) {
  .CardsList {
    grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
    padding-right: 0px;
    justify-items: center;
  }
}
