.achievementCard {
  .achievementFormRow {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    column-gap: 40px;
    .imageInputWrapper {
      width: 128px;
      height: 128px;
    }
    .achievementImageInput {
      background: none;
    }
  }
  .achievementFormCol {
    display: flex;
    flex-direction: column;
  }
  .achievementsOwnersFilter {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 20px;
    .achievementsOwnersCount {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 110%;
      color: #96a0b0;
      padding-top: 20px;
    }
  }
  .betweenRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .removeBtn {
    cursor: pointer;
    font-family: 'SourceSansPro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #96a0b0;
    background-color: transparent;
    border: 0px;
    outline: none;
    width: auto;
    padding: 0px;
  }
  .EditAchievementFormButton {
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    button {
      min-width: 120px;
    }
  }
}

@media screen and (max-width: 991px) {
  .achievementCard {
    padding: 0px;
    .achievementFormRow {
      display: block;
      .imageInputWrapper {
        width: 100%;
        height: auto;
      }
    }
    .achievementFormCol {
      padding: 1rem;
    }
    .removeBtn {
      font-size: 13px;
      svg {
        width: 15px;
        height: 16px;
      }
    }
    .achievementOwnersBlock {
      padding: 1rem;
    }
    .betweenRow {
      padding: 1rem;
      button {
        font-size: 13px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
    .EditAchievementFormButton {
      flex-direction: column-reverse;
      button {
        min-width: 100%;
        margin-bottom: 20px;
      }
    }
}