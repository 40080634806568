.topDateInput {
  display: flex;
  flex-direction: column;
}
.leftDateInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottomDateInput {
  display: flex;
  flex-direction: column-reverse;
  label {
    padding-top: 10px;
  }
}
.rightDateInput {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.label {
  cursor: pointer;
  color: #96a0b0;
  font-weight: 500;
  font-size: 12px;
}
.inputBlock {
  margin-top: 0.3rem;
  max-width: 60px;
  max-height: 50px;
  border: 1.5px solid #96A0B0;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0.7rem;
  display: flex;
  flex-direction: row;
  input {
    cursor: pointer;
    font-family: 'SourceSansPro';
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    border: 0px;
    outline: none;
    &::placeholder {
      color: #ccd1d8;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
