.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}
.slidersContainer {
  text-align: center;
}
.infoContainerControls {
  width: 100%;
  margin-bottom: 16px;
  display: none;
}
.slidersContainer {
  width: 50%;
  padding: 0 10px;
}
.Achievements {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
}
.AchievementsHeader {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  grid-column-gap: 35px;
  column-gap: 35px;
}
.slidersContainerButton {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  white-space: nowrap;
  font-family: 'SourceSansPro';
  max-width: 365px;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 0.75rem 1rem;
  outline: none;
  cursor: pointer;
  border: 2px solid #5f37ff;
  color: #5f37ff;
  background: #ffffff;
  &:hover,
  &:active {
    border: 2px solid #5f37ff;
    color: #ffffff;
    background: #5f37ff;
  }
}
.slidersContainerButtonActive {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  white-space: nowrap;
  font-family: 'SourceSansPro';
  max-width: 365px;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 0.75rem 1rem;
  outline: none;
  cursor: pointer;
  border: 2px solid #5f37ff;
  color: #ffffff;
  background: #5f37ff;
}
.AchievementsUserAvatar {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .Achievements {
    padding: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .AchievementsHeader {
    grid-template-columns: 1fr;
    row-gap: 12px;
  }
}

@media screen and (max-width: 991px) {
  .twoColumns {
    display: block;
  }
  .infoContainerControls {
    display: flex;
  }
}
@media screen and (max-width: 480px) {

}