.Projects {
  padding-top: 40px;
  display: flex;
  flex-direction: column;

  .filtersContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .CardsTable {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    column-gap: 30px;
    row-gap: 30px;
    padding-right: 10px;
  }

  .projectCard {
    cursor: pointer;
    min-height: 255px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 35px;
    border-radius: 12px;
    background: #ffffff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .cardTitle {
      color: #2c2f36;
      font-weight: bold;
      font-size: 28px;
      word-break: break-word;
      margin-bottom: 20px;
    }
    .cardImg {
      width: 180px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cardIcon {
      width: 100%;
      height: auto;
      display: block;
    }
    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }

  .searchText {
    font-size: 16px;
    line-height: 24px;
    color: #96a0b0;
    margin: 0 auto;
  }
}
.ProjectsLoadScreen {
  position: relative;
  margin: 14%;
}
@media screen and (max-width: 480px) {
  .Projects {
    .CardsTable {
      padding-right: 0px;
      margin: 0 1rem;
    }
  }

}