.LoadScreen {
  position: absolute;
  top: 45%;
  right: 55%;

  .mainFountainG {
    position: relative;
    width: auto;
    height: 28px;
    margin: auto;
    min-width: 221px;
  }
}
