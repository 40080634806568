.TextInput {
  position: relative;
  font-family: "Gordita";
  label {
    cursor: pointer;
    padding-left: 1rem;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
  }

  .inputBlock {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #eff2f4;
    background-color: #eff2f4;
    z-index: 1;
    height: 100%;

    input {
      padding-left: 15px;
      padding-bottom: 10px;
      padding-top: 10px;
      cursor: pointer;
      width: 80%;
      background-color: transparent;
      color: #96a0b0;
      font-family: 'SourceSansPro';
      font-weight: normal;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #96a0b0;
        font-family: 'SourceSansPro' ;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
}
