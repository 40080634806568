.Achievements {
  background-color: #ffffff;
  padding: 2rem;
  height: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  table {
    td {
      &:last-child {
        text-align: center !important;
      }
    }
  }
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 350px;
  column-gap: 40px;
}
.AchievementsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.achievementsHistoryTitle {
  font-size: 30px;
  font-weight: 700;
  color: #2c2f36;
}
.modal {
  display: flex;
  padding: 1rem;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-box-shadow: 2px 10px 19px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 19px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 10px 19px -10px rgba(0, 0, 0, 0.75);
  max-width: 500px;
  .modalImage {
    border-radius: 6px;
    width: 125px;
    overflow: hidden;
    height: 125px;
    background-color: #ebeef1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
    svg {
      height: 125px;
      width: 125px;
    }
  }
  .infoContainerControls {
    width: 100%;
    margin-bottom: 16px;
    display: none;
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 2rem;
    padding-right: 3rem;
    .modalSubTitleMobile,
    .modalSubTitle {
      color: #5f37ff;
      font-size: 14px;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
    .modalSubTitleMobile {
      display: none;
    }
    .modalTitle {
      padding-bottom: 1rem;
      color: #2c2f36;
      font-size: 18px;
    }
    .modalDescription {
      color: #2c2f36;
      font-size: 14px;
    }
  }

  .modalDate {
    color: #ccd1d8;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 1rem;
  }
  .modalClose {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 1rem;
    color: #eaecef;
  }
}
.slidersContainer {
  text-align: center;
}
.slidersContainerButton {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  white-space: nowrap;
  font-family: 'SourceSansPro';
  max-width: 365px;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 0.75rem 1rem;
  outline: none;
  cursor: pointer;
  border: 2px solid #5f37ff;
  color: #5f37ff;
  background: #ffffff;
  &:hover,
  &:active {
    border: 2px solid #5f37ff;
    color: #ffffff;
    background: #5f37ff;
  }
}
.slidersContainerButtonActive {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  white-space: nowrap;
  font-family: 'SourceSansPro';
  max-width: 365px;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 0.75rem 1rem;
  outline: none;
  cursor: pointer;
  border: 2px solid #5f37ff;
  color: #ffffff;
  background: #5f37ff;
}
.infoContainerControls {
  width: 100%;
  margin-bottom: 16px;
  display: none;
}
.slidersContainer {
  width: 50%;
  padding: 0 10px;
}
@media screen and (max-width: 991px) {
  .achievementsHistoryTitle {
    padding: 1rem 1rem 0rem 1rem;
  }
  .twoColumns {
    display: block;
  }
  .AchievementsHeader {
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal {
    max-width: 290px;
    padding: 0px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .imageWrapper {
      width: 115px;
      height: 115px;
    }
    svg {
      width: 115px;
      height: 115px;
    }

    .modalClose {
      display: block;
    }
    .modalImage {
      width: 100%;
      height: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-bottom: 24px;

      svg {
        width: 100%;
        height: auto;
      }
    }
    .modalContent {
      text-align: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      .modalTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
      }
      .modalSubTitleMobile {
        font-size: 12px;
        display: block;
      }
      .modalSubTitle {
        display: none;
      }
      .modalDescription {
        color: #56595e;
        padding-bottom: 40px;
      }
    }
  }

  .infoContainerControls {
    display: flex;
  }
}

@media screen and (max-width: 480px) {
  .Achievements {
    padding: 1rem;
  }
}