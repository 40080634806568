.CardsTable {
  max-height: 318px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  padding-right: 10px;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    border-radius: 3px;
    background-color: #e4e6ea;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #96a0b0;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #ccd1d8;
  }
}
.Card {
  text-align: left;
  cursor: pointer;
  border: 0px;
  outline: none;
  padding: 0px;
  background-color: transparent;
  &:hover {
    .frontSide {
      display: none;
    }
    .backSide {
      display: block;
    }
  }
  .frontSide {
    min-height: 144px;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 12px;
    background: #F8F8F8;
    overflow: hidden;
  }
  .backSide {
    min-height: 144px;
    display: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 12px;
    background: #5f37ff;
    color: #ffffff;
    overflow: hidden;
  }
}
.LoadScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 200px;
}

.EmptyTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  .Text {
    font-size: 14px;
    font-family:'SourceSansPro';
    font-weight: 400;
    color: #ccd1d8;
  }
}

@media screen and (max-width: 1050px) {
  .CardsTable {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 768px) {
  .CardsTable {
    max-height: unset;
  }
}