.phoneReceivedFrontCard {
  display: block;
  .cardRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .userInfoRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .bcoins {
    color: #2c2f36;
    font-size: 16px;
    font-weight: 500;
  }
  .createdDate {
    color: #ccd1d8;
    font-size: 12px;
    font-weight: 500;
  }
  .givenToName {
    font-size: 18px;
    color: #2c2f36;
    font-weight: 500;
  }
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    svg {
      padding: 1rem;
      background-color: #ebeef1;
    }
  }
  .name {
    color: #2c2f36;
    font-size: 18px;
    font-weight: 500;
  }
  .category {
    color: #ccd1d8;
    font-size: 17px;
    font-weight: 500;
  }
}
