.Sorter {
  background-color: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;
  .SortDesc,
  .SortAsc {
    width: 20px;
    height: 20px;
    margin-bottom: -6px;
    &:hover {
      path {
        fill: #323232;
        fill-opacity: 1;
      }
    }
  }
}
