.ProjectsDetail {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;

  .projectInfo {
    display: grid;
    grid-template-columns: 256px 1fr;
    column-gap: 40px;
    min-height: 256px;

    .cardPicture {
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
    .cardIcon {
      max-width: 256px;
      max-height: 256px;
      height: auto;
      width: 100%;
    }
    .projectText {
      display: flex;
      flex-direction: column;

      .projectTitle {
        font-size: 64px;
        color: #2c2f36;
        margin-bottom: 25px;
        font-weight: 900;
        word-break: break-all;
      }
      .projectDescription {
        font-size: 16px;
        line-height: 24px;
        color: #96a0b0;
        max-width: 450px;
      }
    }
  }
}

.breadcrumbsBlock {
  display: flex;
  flex-direction: row;

  .breadcrumbsArrow {
    color: #2c2f36;
    fill: #2c2f36;
    margin-right: 10px;
  }
  .breadcrumb {
    color: #2c2f36;
    font-size: 14px;
    margin-right: 10px;
  }
  .breadcrumbActive {
    font-size: 14px;
    color: #5f37ff;
    margin-right: 10px;
  }
}

.projectTables {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 35px;
  margin-top: 70px;
  .projectTablesBlock {
    display: flex;
    flex-direction: column;
  }
  .table {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 30px;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
  }
  .tableNotMain {
    a {
      .rowItem {
        padding: 8px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 44px;
        background: transparent;
        .imgBlock {
          width: 44px;
          height: 44px;
        }
        .infoBlock {
          padding-bottom: 4px;
          padding-top: 4px;
        }
      }
    }
    a:nth-child(2n + 1) {
      .rowItem {
        background: #f5f6f6;
        padding: 8px;
      }
    }
  }
  .twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    column-gap: 16px;
    row-gap: 8px;
  }
  .oneCol {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    column-gap: 16px;
    row-gap: 8px;
  }

  .rowItem {
    display: flex;
    flex-direction: row;
    height: 52px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-bottom: 8px;
    background: #f5f6f6;

    &:last-child {
      margin-bottom: 0px;
    }

    .imgBlock {
      width: 52px;
      height: 52px;
      margin-right: 12px;
      border-radius: 8px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    .infoBlock {
      display: flex;
      flex-direction: column;
      padding-bottom: 8px;
      padding-top: 8px;
      .name {
        color: #2c2f36;
        font-size: 16px;
      }
      .role {
        margin-top: 5px;
        font-size: 14px;
        color: #96a0b0;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .projectTables {
    grid-template-columns: 2fr 2fr;
    .table {
      .twoCol {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        column-gap: 16px;
        row-gap: 8px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .projectTables {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
  .ProjectsDetail {
    .projectInfo {
      display: flex;
      flex-wrap: wrap;
    }
  }

}
@media screen and (max-width: 480px) {
  .projectTables {
    .table {
      padding: 1rem;
    }
  }

  .ProjectsDetail {
    .projectInfo {
      flex-direction: column;
      .projectText {
        .projectTitle {
          text-align: center;
          font-size: 24px;
          margin: 16px 0px;
        }
      }
    }
  }
}
