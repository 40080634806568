.Footer {
  background: #111315;
  height: 90px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  width: 100%;
  margin-top: 20px;
  font-weight: 500;
  .copyright {
    color: #88898a;
  }
  .footerLinks {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .link {
      text-decoration: none;
      color: #ffffff;
      &:hover {
        text-decoration: underline;
      }
    }
    .separator {
      font-weight: 800;
      margin-right: 15px;
      margin-left: 15px;
      color: #ffffff;
    }
  }
}
