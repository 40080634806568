@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/sourcesanspro.eot');
  src: url('../assets/fonts/sourcesanspro.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/sourcesanspro.woff2') format('woff2'),
  url('../assets/fonts/sourcesanspro.woff') format('woff'),
  url('../assets/fonts/sourcesanspro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/sourcesansprosemibold.eot');
  src: url('../assets/fonts/sourcesansprosemibold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/sourcesansprosemibold.woff2') format('woff2'),
  url('../assets/fonts/sourcesansprosemibold.woff') format('woff'),
  url('../assets/fonts/sourcesansprosemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/sourcesansprobold.eot');
  src: url('../assets/fonts/sourcesansprobold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/sourcesansprobold.woff2') format('woff2'),
  url('../assets/fonts/sourcesansprobold.woff') format('woff'),
url('../assets/fonts/sourcesansprobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;

}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/sourcesansproblack.eot');
  src: url('../assets/fonts/sourcesansproblack.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/sourcesansproblack.woff2') format('woff2'),
  url('../assets/fonts/sourcesansproblack.woff') format('woff'),
  url('../assets/fonts/sourcesansproblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/sourcesansproblackitalic.eot');
  src: url('../assets/fonts/sourcesansproblackitalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/sourcesansproblackitalic.woff2') format('woff2'),
  url('../assets/fonts/sourcesansproblackitalic.woff') format('woff'),
  url('../assets/fonts/sourcesansproblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}


