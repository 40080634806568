.EventsSecretScreen {
  padding: 2rem;
}
.CardsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(574px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
  padding-right: 10px;
}
