.UsersTab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}
.twoFlexColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.breadcrumbsBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  .breadcrumbsArrow {
    color: #2c2f36;
    fill: #2c2f36;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .breadcrumb {
    color: #5f37ff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    margin-right: 10px;
    cursor: pointer;
  }
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.formBlock,
.tableBlock {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 6px;
}
@media screen and (max-width: 768px) {
  .UsersTab {
    grid-template-columns: 1fr;
  }
}