.card {
  min-height: 300px;
  display: flex;
  padding: 2rem;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.blaceTheme {
  text-align: center;
  color: #96A0B0;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 4px;
}
.givenToName {
  text-align: center;
  color: #2c2f36;
  font-size: 26px;
  font-weight: 500;
}
.cardFlexCols {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.AchievementsUserAvatar {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 20px;
}

.avatar {
  img {
    border-radius: 12px;
    width: auto;
    height: 150px;
  }
  svg {
    border-radius: 12px;
    padding: 1rem;
    background-color: #ebeef1;
  }
}
.name {
  text-align: center;
  color: #2c2f36;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .givenToName {
    font-size: 22px;
  }
  .blaceTheme {
    font-size: 20px;
  }
}