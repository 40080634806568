.PurchaseListTab {

}
.pageContent {
  padding: 2rem;
  background: white;
  border-radius: 12px;
}
.filterBlock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.filterCell {
  display: flex;
}