.SortInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    margin-right: 1rem;
  }
  .arrow {
    width: 12px;
    height: 12px;
  }
  .activeArrow {
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
  }
}
