.NavMobile {
  display: none;
  position: relative;
  outline: none;
  .navMobileTitle {
    cursor: pointer;
    text-transform: uppercase;
    color: #96a0b0;
    font-weight: 600;
    font-size: 14px;
    transition: 0.3s;
    &:hover,
    &:active {
      color: rgb(95, 55, 255);
      svg {
        fill: rgb(95, 55, 255);
      }
    }
    svg {
      margin-right: 0.5rem;
      width: 20px;
      height: 11px;
      fill: #96A0B0;
      transition: 0.3s;
    }
  }
  .navMobileList {
    z-index: 999;
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    min-width: 200px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0px 26px 26px rgba(0, 12, 58, 0.06);
    li {
      padding-bottom: 24px;
      color: #96a0b0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s;
      &:hover,
      &:active {
        color: rgb(95, 55, 255);
      }
      &:last-child {
        padding-bottom: 0px;
      }
      svg {
        margin-right: 20px;
        margin-bottom: -6px;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &:focus {
    .navMobileList {
      display: block;
    }
  }
  .username {
    font-size: 12px;
    color: #96a0b0;
  }
  .points {
    font-size: 12px;
    svg {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      color: #96a0b0;
    }
  }
}
@media screen and (max-width: 991px) {
  .NavMobile {
    display: flex;
    align-items: center;
  }
}
