.PurchaseDetail {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;

  .purchaseInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    min-height: 256px;

    .cardPicture {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 24px;
      overflow: hidden;
      height: 338px;
      max-width: 520px;
      width: 100%;
      position: relative;
    }
    .cardPictureLabel {
      position: absolute;
      top: -22px;
      left: -75px;
      background: #5f37ff;
      color: #ffffff;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      transform: rotate(-45deg);
      padding: 60px;
      padding-bottom: 10px;
      text-align: center;
    }
    .offerCountProgress{
      background: #CCD1D8;
      border-radius: 8px;
      width: 100%;
      height: 16px;
      position: relative;
      margin-top: 16px;
      overflow: hidden;
    }
    .offerCountProgressBar {
      background: #5F37FF;
      border-radius: 8px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: right;
    }
    .offerCountProgressBarText {
      padding: 0 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
    }
    .offerCountProgressDesc {
      display: flex;
      flex-direction: column;
    }
    .offerCountProgressCoin,
    .purchaseDescriptionPrice {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #2C2F36;
      margin-bottom: 5px;
    }
    .offerCountProgressCoinSubtitle{
      font-size: 16px;
      line-height: 19px;
      color: #2C2F36;
    }
    .NumInputContainer {
      margin-top: 32px;
      display: flex;
      align-items: flex-end;
    }
    .purchaseText {
      display: flex;
      flex-direction: column;

      .purchaseTitle {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: #2C2F36;
        margin-bottom: 12px;
      }
      .purchaseDescription {
        font-size: 16px;
        line-height: 19px;
        color: #96A0B0;
        margin-bottom: 30px;
      }
    }
  }
}

.breadcrumbsBlock {
  display: flex;
  flex-direction: row;

  .breadcrumbsArrow {
    color: #2c2f36;
    fill: #2c2f36;
    margin-right: 10px;
  }
  .breadcrumb {
    color: #2c2f36;
    font-size: 14px;
    margin-right: 10px;
  }
  .breadcrumbActive {
    font-size: 14px;
    color: #5f37ff;
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .PurchaseDetail {
    padding: 0;
    .purchaseInfo {
      grid-template-columns: 1fr;
      .cardPicture {
        max-width: 100%;
        margin-bottom: 16px;
      }
    }
  }

}
@media screen and (max-width: 480px) {
  .PurchaseDetail {
    .purchaseInfo {cardPictureLabel
      .purchaseDescriptionPrice {
        margin-bottom: 0px;
      }
      .purchaseText {
        .purchaseTitle,
        .offerCountProgressCoin {
          font-size: 24px;
          line-height: 24px;
        }
        .purchaseDescription {
          margin-bottom: 24px;
        }
      }
      .cardPictureLabel {
        top: -33px;
        left: -79px;
        font-size: 10px;
        line-height: 13px;
        padding: 60px;
        padding-bottom: 6px;
      }
      .cardPicture {
        height: 162px;
        max-width: 288px;
        margin: 0 auto;
        margin-bottom: 16px;
      }
    }
  }
}
