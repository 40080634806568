.Button {
  font-family: 'SourceSansPro' !important;
  max-width: 365px;
  background: #5f37ff;
  border-radius: 6px;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  padding: 0.75rem 1rem;
  border: 2px solid #5f37ff;
  outline: none;
  cursor: pointer;
  svg {
    display: block;
  }
  &:hover {
    border: 2px solid #5f37ff;
    color: #5f37ff;
    background: #ffffff;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      border: 2px solid #5f37ff;
      color: #ffffff;
      background: #5f37ff;
    }
  }
}
