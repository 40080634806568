.Nav {
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    li {
      color: #2c2f36;
      font-weight: 500;
      cursor: pointer;
      &:hover,
      .active {
        color: #5f37ff;
      }
      a {
        color: inherit;
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .Nav {
    display: none;
  }
}
