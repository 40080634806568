.RatingsSecretScreen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  padding: 2rem;
  padding-bottom: 0px;
}
.tableContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
