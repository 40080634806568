.GridTable {
  width: 100%;
  thead {
    tr {
      width: calc(100% - 2em);
      th {
        padding: 0.8rem;
        color: #96A0B0;
      }
    }
  }
  tbody {
    display: block;
    padding-right: 1rem;
    max-height: 700px;
    overflow-y: auto;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
    tr {
      &:hover {
        background-color: #5f37ff;
        color: #fff;
        svg {
          circle {
            fill: #fff;
          }
          path {
            fill: #5f37ff;
          }
        }
      }
    }
  }

  th {
    text-align: left;
    color: #ccd1d8;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 1rem;
  }
  tr {
    display: table;
    width: 100%;
    cursor: pointer;

    & td:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
    & td:first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
  .gray {
    background-color: #f5f6f6;
  }
  .highlight {
    background-color: #5f37ff;
  }
  td {
    padding: 0.4rem;
    vertical-align: middle;
    font-weight: 500;
    font-size: 14px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.flexColInTable {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.LoadScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 200px;
}

.EmptyTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  .Text {
    font-size: 14px;
    font-family: 'SourceSansPro';
    font-weight: 400;
    color: #ccd1d8;
  }
}
@media screen and (max-width: 991px) {
  .GridTable {
    th {
      font-size: 12px;
    }

    tbody {
      padding-right: 0px;
      td {
      }
    }
  }
}
