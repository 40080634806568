.title {
  font-weight: 500;
  font-size: 18px;
}
.subTitle {
  font-weight: 500;
  font-size: 16px;
}
@media screen and (max-width: 991px) {
  .title {
    font-size: 12px;
  }
  .subTitle {
    font-size: 12px;
  }
}
