.Store {
  padding: 1rem;
  a {
    width: 100%;
  }
}
.StoreLoadScreen {
  position: relative;
  margin: 14%;
}
.StoreTableEmptyScreen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccd1d8;
  margin-top: 100px;
}
.CardsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}
.filterContainer {
  display: flex;
  align-items: flex-end;
  .NumInputContainer {
    display: flex;
    margin-left: 40px;
    align-items: center;
    .NumInputContainerDefis {
      width: 16px;
      height: 1px;
      background: #96A0B0;
      margin: 16px 8px 0 8px;
    }
    label {
      height: 12px;
    }
  }
}

.filtersRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 768px) {
  .filtersRow {
    flex-wrap: wrap;
  }
  .filterContainer {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 570px) {
  .Store {
    .CardsTable {
      max-width: 288px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .filtersRow {
    flex-direction: column;
    align-items: flex-start;
  }
  .filterContainer{
    .NumInputContainer {
      margin-top: 12px;
      margin-left: 0px;
    }
  }
  .filterContainer {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }
}
