.card {
  min-height: 250px;
  display: flex;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
  .cardBlock {
    padding: 8px 12px 24px 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .cardAvatar {
    height: 140px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .cardLabel {
    background: #ffffff;
    border-radius: 20px;
    padding: 3px 7px;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #2C2F36;
    position: absolute;
    right: 16px;
    top: 16px
  }
  .type {
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    color: #2C2F36;
    margin-bottom: 12px;
  }
  .offerBlock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    .offerTitle,
    .offerPrice {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #2C2F36;
    }
    .offerTitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
    .offerPrice {
      white-space: nowrap;
    }

  }
  .descriptionProducts {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #96A0B0;
    flex: 1;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 116px;
  }

  .descriptionCrowdfunding {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #96A0B0;
    flex: 1;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 58px;
    margin-bottom: 20px;
  }

  .offerCountProgress{
    background: #CCD1D8;
    border-radius: 8px;
    width: 100%;
    height: 8px;
    position: relative;
    overflow: hidden;
  }
  .offerCountProgressBar {
    background: #5F37FF;
    border-radius: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .offerCountProgressDesc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
  }
  .offerCountProgressPersent,
  .offerCountProgressCoin {
    font-weight: 600;
    font-size: 17px;
  }
  .offerCountProgressDescRight {
    text-align: right;
  }
}