.SliderCoin {
  height: 85px;
  overflow: hidden;
  padding: 15px;
  width: calc(100% - 30px);
  label {
    cursor: pointer;
    color: #96a0b0;
    font-weight: 500;
    font-size: 12px;
  }
  .valuesRange {
    margin-top: 0.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .value {
      color: #ccd1d8;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 768px) {
    .SliderCoin{
      padding-right: 20px;
    }
}
