.ImageInputBlock {
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebeef1;
  cursor: pointer;
  .imagePresentBlock {
    position: relative;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    svg {
      color: gray;
      border: 2px solid gray;
      border-radius: 50%;
      position: absolute;
      bottom: 4px;
      right: 4px;
      width: 24px;
      height: 24px;
    }
  }

  input {
    display: none;
  }
}
