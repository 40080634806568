.remove-form {
  background: white;
  border-radius: 8px;
  padding: 12px;
}
.remove-form__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2C2F36;
  max-width: 180px;
  margin: 0 auto;
  text-align: center;
}
.remove-form__textarea {
  margin-top: 16px;
}
.remove-form__btn {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}