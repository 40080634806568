.UserInfo {
  z-index: 2;
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  .avatar {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #ebeef1;
    overflow: hidden;
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
  .userInfoBlock {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 500;
    .username {
      margin-bottom: 5px;
      font-size: 16px;
    }
    .balance {
      font-size: 16px;
      white-space: nowrap;
    }
  }
  .dropdownWrapper {
    outline: none;
    width: 70%;
    height: 150%;
    right: 0px;
    top: 0px;
    position: absolute;
    background-color: transparent;
    &:focus .dropdown {
      display: flex;
    }
    .dropdown {
      display: none;
      width: 140px;
      float: right;
      top: 70px;
      position: relative;
      border-radius: 12px;
      background-color: #ffffff;
      color: #96a0b0;
      font-weight: 500;
      font-size: 16px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding: 1rem;

      -webkit-box-shadow: 0px 26px 26px rgba(0, 12, 58, 0.06);
      -moz-box-shadow: 0px 26px 26px rgba(0, 12, 58, 0.06);
      box-shadow: 0px 26px 26px rgba(0, 12, 58, 0.06);
      &:focus {
        display: flex;
      }
      li {
        cursor: pointer;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0px;
        }
        &:focus {
          text-decoration: underline;
        }
        svg {
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .UserInfo {
    display: none;
  }
}
