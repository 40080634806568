.Checkbox {
    display: flex;
    align-items: center;

    label:not(.checkBoxLabel) {
      position: relative;
      cursor: pointer;
      margin-right: 16px;
      display: block;
      padding: 16px 0 11px 16px;
      font-size: 11px;
      line-height: 110%;
      color: #96A0B0;
      &:before {
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background: #d1cfce;
        background: #FFFFFF;
        box-shadow: 0px 1px 2px 1px rgba(0, 12, 58, 0.1);
        line-height: 23px;
        font-size: 16px;
        font-weight: 700;
        color: #1f4ea2;
        text-align: center;
      }
    }
  
    input[type='checkbox'] {
      display: none;
      &:checked + label:before {
        content: '\2713';
        background-color: #5F37FF;
        color: #ffffff;
      }
    }

    .checkBoxLabel {
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 24px;
      color: #96A0B0;
      cursor: pointer;
    }
  }
  