.GridTableWithToogleRows {
  width: 100%;
  thead {
    tr {
      width: calc(100% - 2em);
      th {
        padding: 0.8rem;
      }
    }
  }
  tbody {
    display: block;
    padding-right: 1rem;
    max-height: 600px;
    overflow-y: auto;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
    tr {
      &:hover {
        background-color: #5f37ff;
        color: #fff;
      }
    }
    tr.openRow  {
      background-color: transparent;
      color: inherit;
     td {
       padding: 0px;
     }
     }
  }

  th {
    text-align: left;
    color: #ccd1d8;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 1rem;
  }
  tr {
    cursor: pointer;
    display: table;
    & > div {
      width: 100%;
    }

    width: 100%;
    & td:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
    & td:first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
  .gray {
    background-color: #f5f6f6;
  }
  .highlight {
    background-color: #5f37ff;
  }
  td {
    padding: 0.4rem;
    vertical-align: middle;
    font-weight: 500;
    font-size: 14px;
  }
}
.flexColInTable {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.toogledRow {
  display: none;
}
.toogledRowActive {
  display: flex;
  padding: 0.8rem 0;
}

.LoadScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-right: 45%;
  min-height: 200px;
}

.EmptyTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  .Text {
    font-size: 14px;
    font-family: 'SourceSansPro';
    font-weight: 400;
    color: #ccd1d8;
  }
}
@media screen and (max-width: 991px) {
}
