a {
  color: inherit;
  text-decoration: none;
}
.card {
  min-height: 300px;
  display: flex;
  padding: 2rem;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .givenToName {
    text-align: center;
    color: #2c2f36;
    font-size: 17px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 2px;
  }
  .avatar {
    text-align: center;
    width: 100%;
    height: 88px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      height: 100%;
      display: block;
    }
    svg {
      border-radius: 5px;
      padding: 1rem;
      background-color: #eff2f4;
    }
  }
  .blaceTheme {
    text-align: center;
    color: #96a0b0;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 15px;
  }
  .name {
    text-align: center;
    color: #2c2f36;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    margin-top: 15px;
  }
  .description {
    line-height: 120%;
    color: #96a0b0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    flex: 1 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .createdDate {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    color: #cacfd7;
  }
}
